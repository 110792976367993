<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar
                class="list-unstyled menu-categories"
                tag="ul"
                :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
            >
                <!-- call-center-boss -->
                <SidebarItem v-if="isCallCenterBoss()" icon="users" link="/admin/users" :label="$t('admin.sidebar.users')" />
                <SidebarItem
                    v-if="isCallCenterBoss()"
                    icon="feedbacks"
                    link="/call-center-boss/feedbacks"
                    :label="$t('admin.sidebar.feedbacks')"
                    :badgeCount="$store.state.feedback.count"
                />
                <SidebarItem v-if="isCallCenterBoss()" icon="chat" link="/admin/chat" :label="$t('admin.sidebar.chat')" :badgeCount="$store.state.chat.newChatCount" />
                <SidebarItem v-if="isCallCenterBoss()" icon="questionaree" link="/admin/questionnaire" :label="$t('admin.sidebar.questionnaire')" />
                <SidebarItem v-if="isCallCenterBoss()" icon="category" link="/admin/treatment-category" :label="$t('table.treatment_category')" />
                <SidebarItem v-if="isCallCenterBoss()" icon="orders" link="/admin/orders" :label="$t('admin.sidebar.orders')" />

                <!-- company -->
                <SidebarItem
                    v-if="isCLDomain() && (isCompany() || isCompanyManager() || isCompanyLaborant())"
                    icon="orders"
                    link="/company/orders"
                    :label="$t('admin.sidebar.orders')"
                />
                <SidebarItem v-if="isCLDomain() && (isCompany() || isCompanyManager())" icon="analyzes" link="/company/analyzes" :label="$t('admin.sidebar.analyzes')" />
                <SidebarItem v-if="isCLDomain() && (isCompany() || isCompanyManager())" icon="reports" link="/company/reports" :label="$t('admin.sidebar.reports')" />

                <!-- admin  -->
                <SidebarItem v-if="isAdmin() || isCallCenter()" icon="users" link="/admin/users" :label="$t('admin.sidebar.users')" />
                <SidebarItem v-if="isAdmin()" icon="orders" link="/admin/orders" :label="$t('admin.sidebar.orders')" />
                <SidebarItem v-if="isAdmin()" icon="reports" link="/admin/reports" :label="$t('admin.sidebar.reports')" />
                <SidebarItem v-if="isAdmin()" icon="comments" link="/admin/comments" :label="$t('admin.sidebar.comments')" />
                <SidebarItem v-if="isAdmin()" icon="black-list" link="/admin/black-list" :label="$t('admin.sidebar.black-list')" />
                <SidebarItem v-if="isAdmin()" icon="medical_cards" link="/admin/medical_cards" :label="$t('admin.sidebar.medical_cards')" />
                <SidebarItem v-if="isAdmin()" icon="services" link="/admin/services" :label="$t('admin.sidebar.services')" />
                <SidebarItem v-if="isAdmin()" icon="professions" link="/admin/professions" :label="$t('admin.sidebar.professions')" />
                <SidebarItem v-if="isAdmin()" icon="symptoms" link="/admin/symptoms" :label="$t('admin.sidebar.symptoms')" />
                <SidebarItem v-if="isAdmin()" icon="rate" link="/admin/rate" :label="$t('admin.sidebar.rate')" />
                <SidebarItem v-if="isAdmin()" icon="list" link="/admin/reasons" :label="$t('admin.sidebar.reasons')" />
                <SidebarItem v-if="isAdmin() || isCallCenterBoss()" icon="map" link="/admin/map" :label="$t('admin.sidebar.map')" />
                <SidebarItem v-if="isAdmin()" icon="conversations" link="/admin/conversations" :label="$t('admin.sidebar.conversations')" />
                <SidebarItem v-if="isAdmin()" icon="one-id" link="/admin/one-id-statistics" :label="$t('admin.sidebar.One_ID_statistics')" />
                <SidebarItem v-if="isAdmin()" icon="sms-gateway" link="https://getsms.uz/site/login" label="СМС ШЛЮЗ" external />
                <SidebarItem v-if="isAdmin()" icon="ip_telephony" link="/admin/ip-telephony" :label="$t('admin.sidebar.ip_telephony')" />
                <SidebarItem v-if="isAdmin()" icon="category" link="/admin/treatment-category" :label="$t('table.treatment_category')" />
                <SidebarItem v-if="isAdmin()" icon="self_employed_status" link="/admin/self-employed-status" :label="$t('admin.sidebar.self_employed_status')" />

                <li v-if="isAdmin()" class="role-name">{{ $t('role.call_center') }}</li>
                <!-- call-center -->
                <SidebarItem
                    v-if="isAdmin() || isCallCenter()"
                    icon="feedbacks"
                    link="/admin/feedbacks"
                    :label="$t('admin.sidebar.feedbacks')"
                    :badgeCount="$store.state.feedback.count"
                />
                <SidebarItem
                    v-if="isAdmin() || isCallCenter()"
                    icon="chat"
                    link="/admin/chat"
                    :label="$t('admin.sidebar.chat')"
                    :badgeCount="$store.state.chat.newChatCount"
                />
                <SidebarItem v-if="isAdmin() || isCallCenter()" icon="questionaree" link="/admin/questionnaire" :label="$t('admin.sidebar.questionnaire')" />
                <SidebarItem v-if="isCallCenter()" icon="orders" link="/admin/orders" :label="$t('admin.sidebar.orders')" />

                <li class="role-name" v-if="isAdmin()">{{ $t('role.gp') }}</li>
                <!-- gp -->
                <SidebarItem
                    v-if="isGP() || isAdmin()"
                    icon="support"
                    link="/admin/dispatcher"
                    :label="$t('admin.sidebar.atc-dispatcher')"
                    :badgeCount="$store.state.chat.gpCount"
                />
                <li class="role-name" v-if="isAdmin()">{{ $t('role.content_manager') }}</li>
                <!-- content manager -->
                <SidebarItem v-if="isAdmin() || isContentManager()" icon="news" link="/admin/news" :label="$t('admin.sidebar.news')" />
                <SidebarItem v-if="isAdmin() || isContentManager()" icon="video-podcast" link="/admin/video-podcast" :label="$t('admin.sidebar.video-podcast')" />
                <SidebarItem v-if="isAdmin() || isContentManager()" icon="vebinar" link="/admin/vebinar" :label="$t('admin.sidebar.vebinar')" />
                <!-- <SidebarItem v-if="isAdmin() || isContentManager()" icon="about-company" link="/admin/about-company" :label="$t('admin.sidebar.about_company')" /> -->
                <!-- <SidebarItem v-if="isAdmin() || isContentManager()" icon="about-app" link="/admin/about-app" :label="$t('admin.sidebar.about_app')" /> -->
                <SidebarItem v-if="isAdmin() || isContentManager()" icon="contacts" link="/admin/contacts" :label="$t('admin.sidebar.contact')" />
                <SidebarItem v-if="isAdmin() || isContentManager()" icon="clinic" link="/admin/clinic" :label="$t('admin.sidebar.clinic')" />
            </perfect-scrollbar>
        </nav>
    </div>

    <!--  END SIDEBAR  -->

    <!-- Message modal -->
    <Modal width="600px" :title="$t(`admin.sidebar.notifications`)" @close="close" :is-open="isOpen">
        <template v-slot:body>
            <h5 class="text-warning">{{ $t('notifs.you_have_an_unaccepted_order') }}</h5>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
    .role-name {
        padding: 9px 10px;
        border-bottom: 1px solid #a9aec5;
    }
</style>

<script setup>
    import SidebarItem from '@/components/sidebar/SidebarItem.vue';
    import { useGPSocket, useChatSocket, useFeedbackSocket, useClinicOrderNotifyChannel } from '@/services/socket';
    import { isAdmin, isCallCenter, isCLDomain, isCallCenterBoss, isContentManager, isGP, isCompany, isCompanyManager, isCompanyLaborant } from '@/utils/role';
    import { showMessage } from '@/utils/toast';
    import { onMounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import Modal from '../custom/Modal.vue';
    import { useModal } from '@/composables/use-modal';
    import { toast } from 'vue3-toastify';
    const { t } = useI18n();

    const route = useRoute();
    const store = useStore();

    const { isOpen, open, close } = useModal();
    onMounted(() => {
        //listen company socket
        if (isCompany() || isCompanyManager() || isCompanyLaborant()) {
            useClinicOrderNotifyChannel(() => {
                if (route.name == 'CompanyOrders') {
                    showMessage(t('notifs.you_have_an_unaccepted_order'), toast.TYPE.WARNING, toast.POSITION.BOTTOM_RIGHT);
                } else {
                    open();
                }
            });
        }
        //listen admin role socket
        if (isAdmin()) {
            store.dispatch('chat/getCounts');
            store.dispatch('feedback/getCounts');

            useGPSocket((res) => {
                store.commit('chat/setGpCount');
            });

            useChatSocket(() => {
                store.dispatch('chat/getCounts');
            });

            useFeedbackSocket(() => {
                store.dispatch('feedback/getCounts');
            });
        }

        //listen gp role socket
        if (isGP()) {
            useGPSocket(() => {
                store.commit('chat/setGpCount');
            });
        }
        //listen call center role socket
        if (isCallCenter()) {
            store.dispatch('chat/getCounts');
            store.dispatch('feedback/getCounts');

            useChatSocket(() => {
                store.dispatch('chat/getCounts');
            });

            useFeedbackSocket(() => {
                store.dispatch('feedback/getCounts');
            });
        }

        //listen call center boss role socket
        if (isCallCenterBoss()) {
            store.dispatch('chat/getChatCountsForCallCenterBoss');
            store.dispatch('feedback/getCountsForCallCenterBoss');

            useChatSocket(() => {
                store.dispatch('chat/getChatCountsForCallCenterBoss');
            });

            useFeedbackSocket(() => {
                store.dispatch('feedback/getCountsForCallCenterBoss');
            });
        }
    });
</script>
