import $cookies from 'vue-cookies';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
export function initSocket() {
    window.Pusher = Pusher;

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'anyKey',
        wsHost: 'api.paramedics.uz',
        authEndpoint: 'https://api.paramedics.uz/laravel-websockets',
        encrypted: true,
        forceTLS: false,
        wsPort: 6001,
        wssPort: 6001,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        cluster: 'eu',
        auth: {
            headers: {
                authorization: 'Bearer ' + $cookies.get('user_token'),
            },
        },
    });
}

export function useSocket(callback) {
    if (window.Echo) {
        const userId = $cookies.get('user')?.id;

        window.Echo.channel(`Chat.${userId}`).listen('ChatEvent', (e) => {
            callback(e);
        });
    }
}
export function useOrderChangedSocket(callback) {
    if (window.Echo) {
        const userId = $cookies.get('user')?.id;
        window.Echo.channel(`order_channel_${userId}`).listen('OrderChangedEvent', (e) => {
            callback(e);
        });
    }
}
export function useOrderSocket(callback) {
    if (window.Echo) {
        window.Echo.channel(`order_channel`).listen('OrderChangedEvent', (e) => {
            callback(e);
        });
    }
}
export function useOrderCreatedSocket(callback) {
    if (window.Echo) {
        const userId = $cookies.get('user')?.id;
        window.Echo.channel(`order_created_${userId}`).listen('OrderCreatedEvent', (e) => {
            callback(e);
        });
    }
}

export function useGPSocket(callback) {
    if (window.Echo) {
        window.Echo.channel('gp_channel').listen('GpEvent', (e) => {
            callback(e);
        });
    }
}

export function useChatSocket(callback) {
    if (window.Echo) {
        window.Echo.channel('chat_channel')
            .listen('ChatUpdatedEvent', (e) => {
                callback(e);
            })
            .listen('ChatEvent', (e) => {
                callback(e);
            });
    }
}

export function useFeedbackSocket(callback) {
    if (window.Echo) {
        window.Echo.channel('feedback_channel').listen('FeedbackEvent', (e) => {
            callback(e);
        });
    }
}

export function useClinicOrderNotifyChannel(callback) {
    if (window.Echo) {
        window.Echo.channel(`clinic_order_notify_channel`).listen('ClinicOrderNotifyEvent', (e) => {
            callback(e.order_id);
        });
    }
}
