import $cookies from 'vue-cookies';
import {
    ROLE_DOCTOR,
    ROLE_ADMIN_TEXT,
    ROLE_CONTENT_MANAGER_TEXT,
    ROLE_CALL_CENTER_TEXT,
    ROLE_COMPANY_TEXT,
    ROLE_DOCTOR_TEXT,
    ROLE_MIDDLE_MEDICAL_TEXT,
    ROLE_GP_TEXT,
    ROLES,
    ROLE_CALL_CENTER_BOSS_TEXT,
    ROLE_COMPANY_MANAGER_TEXT,
    ROLE_COMPANY,
    ROLE_ROLE_COMPANY_LABORANT,
    ROLE_ROLE_COMPANY_LABORANT_TEXT,
} from '@/enums/role';
import { isUndefinedOrEmptyStringOrNull } from './reuseble';
export function isCLDomain() {
    return window.location.host == process.env.VUE_APP_CLINIC_HOST;
}
export function isAdmin() {
    return $cookies.get('user')?.role == ROLE_ADMIN_TEXT;
}

export function isContentManager() {
    return $cookies.get('user')?.role == ROLE_CONTENT_MANAGER_TEXT;
}
export function isCallCenter() {
    return $cookies.get('user')?.role == ROLE_CALL_CENTER_TEXT;
}
export function isCallCenterBoss() {
    return $cookies.get('user')?.role == ROLE_CALL_CENTER_BOSS_TEXT;
}
export function isCompany() {
    const role = $cookies.get('user')?.role;
    return role == ROLE_COMPANY_TEXT || role == ROLE_COMPANY;
}
export function isCompanyLaborant() {
    const role = $cookies.get('user')?.role;
    return role == ROLE_ROLE_COMPANY_LABORANT || role == ROLE_ROLE_COMPANY_LABORANT_TEXT;
}

export function isCompanyManager() {
    return $cookies.get('user')?.role == ROLE_COMPANY_MANAGER_TEXT;
}

export function isGP() {
    return $cookies.get('user')?.role == ROLE_GP_TEXT;
}

export function getCurrentUserRole() {
    return $cookies.get('user')?.role;
}
export function getRoleText(role, isMiddleMedical = false) {
    if (isUndefinedOrEmptyStringOrNull(role)) {
        return null;
    }
    if (isMiddleMedical && role == ROLE_DOCTOR_TEXT) {
        return ROLE_MIDDLE_MEDICAL_TEXT;
    }
    if (role == ROLE_DOCTOR && isMiddleMedical) {
        return ROLE_MIDDLE_MEDICAL_TEXT;
    }
    if (isNaN(role)) {
        return role;
    }

    if (ROLES[role]) {
        return ROLES[role];
    }

    return role;
}
